<template>
  <div>
    <v-container fluid>
      <v-layout row wrap>
        <v-flex xs12>
          <p class="body-2 font-weight-black grey--text text--darken-2"> Search by property name </p>
          <v-autocomplete outlined dense label="Search by property name" hide-details prepend-inner-icon="mdi-magnify"
          v-model="filterObj.hotels" multiple class="px-0 px-lg-0" :items="items" item-text="title">
          </v-autocomplete>
        </v-flex>
        <v-flex xs12>
          <div class="body-2 mt-3 font-weight-black grey--text text--darken-2"> Filter by </div>
        </v-flex>
        <v-flex xs12>
          <p class="body-2 my-1 font-weight-black grey--text text--darken-2"> Popular filters </p>
          <div class="pl-3">
            <v-checkbox v-model="filterObj.popularFilter" label="Spa" :value="1" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Free Breakfast" :value="2" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Swimming Pool" :value="3" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Air conditioned" :value="4" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Free WiFi" :value="5" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Parking included" :value="6" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.popularFilter" label="Pet friendly" :value="7" hide-details class="mt-1" multiple></v-checkbox>
          </div>
        </v-flex>
        <v-flex xs12>
          <p class="body-2 mb-1 font-weight-black grey--text text--darken-2"> Star Ratings </p>
          <div class="pl-3">
            <v-chip-group v-model="filterObj.ratings" column active-class="blue--text text--darken-4">
              <v-chip outlined v-for="(item, index) in 5" :key="index" label :value="item">
                {{ item }} <v-icon size="15" class="mx-1"> mdi-star </v-icon>
              </v-chip>
            </v-chip-group>
          </div>
        </v-flex>
        <v-flex xs12>
          <p class="body-2 mb-1 font-weight-black grey--text text--darken-2"> Budget </p>
          <div class="pl-3">
            <v-checkbox v-model="filterObj.budget" label="Less than ₹1000" :value="1" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.budget" label="₹1000 to ₹2000" :value="2" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.budget" label="₹2000 to ₹3500" :value="3" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.budget" label="₹3500 to ₹5500" :value="4" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.budget" label="Greater than ₹5500" :value="5" hide-details class="mt-1" multiple></v-checkbox>
          </div>
        </v-flex>
        <v-flex xs12>
          <p class="body-2 mb-1 font-weight-black grey--text text--darken-2"> Property Type </p>
          <div class="pl-3">
            <v-checkbox v-model="filterObj.propertyType" label="Townhouse" :value="1" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.propertyType" label="Hotel" :value="2" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.propertyType" label="Villa" :value="3" hide-details class="mt-1" multiple></v-checkbox>
            <v-checkbox v-model="filterObj.propertyType" label="Farm House" :value="4" hide-details class="mt-1" multiple></v-checkbox>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ['items'],
  data () {
    return {
      filterObj: {}
    }
  },
  watch: {
    filterObj: {
      handler (filterObj) {
        this.$root.$emit('onFilterChange', filterObj)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style scoped>
  p {
    margin: 10px 0;
  }
</style>
